/* eslint-disable operator-linebreak */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import { LoadingButton } from '@mui/lab';
import { PasswordInput } from '../inputs';
import { toggleSiiForm } from '../../apollo/reactive-variables';
import useNationalTaxServiceCredentialsInput from '../../hooks/useNationalTaxServiceCredentialsInput';
import { useIsMobile } from '../../hooks';

const NationalTaxServiceCredentialsForm = ({
  source,
  onCompleted,
  onError,
  typographyColor,
  fullWidth,
  hideTitle,
}) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { autoFillCompany } = useReactiveVar(toggleSiiForm);
  const { nationalIdentifier, password, onChangeInput, loading, submit, errorMessage, isValid } =
    useNationalTaxServiceCredentialsInput(source, onCompleted, onError);

  const submitButton = (
    <LoadingButton
      variant={isMobile ? 'contained' : 'containedReverse'}
      color="primary"
      size="small"
      disableElevation
      loading={loading}
      onClick={submit}
      disabled={!isValid}
      fullWidth={isMobile}
      sx={(theme) => ({
        backgroundColor: 'white',
        color: 'black',
        '&:hover': {
          backgroundColor: theme.palette.background.light,
        },
      })}
    >
      Registrar empresa
    </LoadingButton>
  );

  return (
    <Stack
      spacing={{ xs: 2, md: 3 }}
      width={{ xs: '100%', md: fullWidth ? '100%' : '48%' }}
      alignItems="center"
    >
      {(!isMobile && !hideTitle) && (
        <Typography variant="h5" align="center" color={typographyColor} sx={{ px: 8 }}>
          Ingresa las credenciales del <br />{' '}
          <strong>{t('National Tax Service credentials')}</strong> de tu empresa
        </Typography>
      )}
      <Stack width={{ xs: '100%', md: '80%' }} spacing={2}>
        <TextField
          name="nationalIdentifier"
          variant="standard"
          placeholder={t('National identifier')}
          value={nationalIdentifier}
          onChange={onChangeInput}
          disabled={autoFillCompany}
          sx={
            !isMobile ? {
              '& .MuiInput-root': {
                '&::before, &::after, &:hover:before': {
                  borderBottomColor: `${typographyColor} !important`,
                },
              },
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: typographyColor,
              },
            } : {}
          }
          InputProps={
            !isMobile ? {
              sx: {
                color: typographyColor,
                backgroundColor: !autoFillCompany ? 'rgba(217,217,217,0.4)' : '',
                borderRadius: 1,
                p: 0.5,
              },
            } : {}
          }
        />
        <PasswordInput
          Component={TextField}
          name="password"
          type="password"
          variant="standard"
          placeholder={`Contraseña ${t('National Tax Service acronym')}`}
          value={password}
          iconColor="text"
          onChange={onChangeInput}
          InputProps={
            !isMobile ? {
              sx: {
                color: typographyColor,
                backgroundColor: 'rgba(217,217,217,0.4)',
                borderRadius: 1,
                p: 0.5,
              },
            } : {}
          }
          sx={
            !isMobile ? {
              '& .MuiInput-root': {
                '&::before, &::after, &:hover:before': {
                  borderBottomColor: `${typographyColor} !important`,
                },
              },
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: typographyColor,
              },
            } : {}
          }
        />
        {errorMessage}
      </Stack>
      {!isMobile && submitButton}
      <Stack direction="column" alignItems="center" spacing={0.2}>
        <Typography variant="body1" color={{ xs: 'primary', md: typographyColor }} align="center">
          Al ingresar declaras haber leído y aceptado los
        </Typography>
        <Link
          target="_blank"
          to="/app/profile/terms"
          component={RouterLink}
          color={{ xs: 'text', md: typographyColor }}
          fontWeight={700}
          underline="hover"
        >
          “Términos y condiciones”
        </Link>
      </Stack>
      {isMobile && submitButton}
    </Stack>
  );
};

NationalTaxServiceCredentialsForm.propTypes = {
  source: PropTypes.string,
  onCompleted: PropTypes.func,
  onError: PropTypes.func,
  typographyColor: PropTypes.string,
  fullWidth: PropTypes.bool,
  hideTitle: PropTypes.bool,
};

NationalTaxServiceCredentialsForm.defaultProps = {
  source: '',
  onCompleted: () => {},
  onError: () => {},
  typographyColor: 'white',
  fullWidth: false,
  hideTitle: false,
};

export default NationalTaxServiceCredentialsForm;

import React from 'react';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Check from '@mui/icons-material/Check';
import DescriptionOutlined from '@mui/icons-material/DescriptionOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import useUploadCompanyDocument from '../../hooks/useUploadCompanyDocument';
import { getCdnUrl } from '../../helpers';

const UploadCreditLineDocumentsInput = ({
  documentType,
  code,
  alreadyUploaded,
  year,
  template,
}) => {
  const { submit, loading } = useUploadCompanyDocument();

  return (
    <Grid
      container
      key={`uploadDocumentRender-${documentType}${year ? `-${year}` : ''}`}
      direction="row"
    >
      <Grid
        item
        sx={{
          borderBottom: 'solid 1px gray.main',
          pb: 1,
          pr: 1,
        }}
        xs={6}
      >
        <Typography
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            color: alreadyUploaded ? 'primary.main' : 'text.primary',
          }}
          variant="body2"
        >
          {year ? `${documentType} ${year}` : documentType}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <label htmlFor={`render-${documentType}${year ? `-${year}` : ''}`}>
          <input
            id={`render-${documentType}${year ? `-${year}` : ''}`}
            type="file"
            onChange={(e) => submit({ year, documentType: code, file: e.target.files[0] })}
            style={{ display: 'none' }}
          />
          <IconButton aria-label="attach" component="span" size="small">
            <AttachFileIcon />
          </IconButton>
        </label>
      </Grid>

      <Grid item xs={2}>
        {loading ? (
          <CircularProgress size={20} color="primary" />
        ) : (
          <IconButton aria-label="attach" component="span" size="small">
            {alreadyUploaded ? <Check color="success" /> : <AccessTimeIcon />}
          </IconButton>
        )}
      </Grid>
      <Grid item xs={2}>
        {template && (
          <IconButton
            aria-label="attach"
            component="span"
            size="small"
            id={`uploadDocumentTemplate${
              year ? `Render-${documentType}-${year}` : `-${documentType}`
            }`}
            onClick={() => window.open(getCdnUrl(template), '_blank')}
          >
            <DescriptionOutlined />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

UploadCreditLineDocumentsInput.propTypes = {
  documentType: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  alreadyUploaded: PropTypes.bool.isRequired,
  year: PropTypes.number, // Use an array to represent years
  template: PropTypes.string,
};

UploadCreditLineDocumentsInput.defaultProps = {
  year: null, // Set years to null by default
  template: null,
};

export default UploadCreditLineDocumentsInput;
